import { request } from 'graphql-request';
import { CONTENTFUL_URL } from '../../../config/contentful-config';

export const HOME_BANNER_KEY = 'img.home-title-bg';
export const LOGIN_BANNER_KEY = 'img.login-splash-bg';
export const SUBSCRIPTION_BANNER_KEY = 'img.subscription-splash-bg';
export const GENRES_BANNER_KEY = 'img.genres-title-bg';
export const THEMES_BANNER_KEY = 'img.themes-title-bg';
export const TITLES_BANNER_KEY = 'img.titles-title-bg';
export const PEOPLE_BANNER_KEY = 'img.people-title-bg';
export const SERIES_BANNER_KEY = 'img.series-title-bg';
export const STUDY_UNIT_DETAIL_KEY = 'img.study-unit-detail-title-bg';

type GetResourceValue = (key: string) => Promise<string | null>;

// eslint-disable-next-line import/prefer-default-export
export const getValue: GetResourceValue = async (
  key: string
): Promise<string | null> => {
  const query = `query GET_RESOURCE_VALUE($key: String){
      resourceLabelCollection(where: {
        key: $key
      }) {
        items {
          value
        }
      }
    }`;

  const response = await request(CONTENTFUL_URL, query, { key });
  const { items } = response.resourceLabelCollection;
  if (items.length === 1) {
    return items[0].value;
  }
  return null;
};
