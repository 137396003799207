import URI from 'urijs';
import clamp from 'lodash/clamp';

export default function generateSrcSetUrl({
  url,
  width = 1152,
  height = 675,
  quality,
  format,
}: {
  url: string;
  width?: number;
  height?: number;
  quality?: number;
  format?: string;
}): string {
  const scaledUrl = (scale: number) => {
    const roundedWidth = Math.round(clamp(width * scale, 100, 4000));
    const roundedHeight = Math.round(clamp(height * scale, 100, 4000));

    const uri = URI(url)
      .addQuery('w', roundedWidth)
      .addQuery('h', roundedHeight);

    if (quality) {
      uri.addQuery('q', quality);
    }

    if (format === 'webp') {
      uri.addQuery('fm', 'webp');
    }

    return `${uri} ${scale}x`;
  };

  return `${scaledUrl(1)}, ${scaledUrl(2)}`;
}
