import type { FC } from 'react';
import { useRouter } from 'next/router';
import Button from '../../shared/components/button/button';
import { onLogin } from '../utils/login';
import { useAuthContext } from '../providers/auth-context';

export const SignInButton: FC = () => {
  const { appAuthService } = useAuthContext();
  const router = useRouter();
  return (
    <div className="u-flex u-space-x-3 u-mt-12 u-w-full">
      <Button
        variant="primary"
        size="lg"
        className="u-px-8 sm:u-px-14 upto-sm:u-flex-1"
        handleButtonClick={() => onLogin(appAuthService!, router)}
      >
        Sign in
      </Button>
    </div>
  );
};
