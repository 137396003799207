import { useEffect, useState } from 'react';
import type { GetServerSideProps, NextPage } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import URI from 'urijs';

import { useAuthContext } from '../auth/providers/auth-context';
import {
  getValue,
  LOGIN_BANNER_KEY,
} from '../shared/api/adapters/contentful-resource-content-api';

import * as breakpoints from '../styles/settings/screens';

import { authenticateUser } from '../auth/utils/authentication';
import generateSrcSetUrl from '../utils/generate-src-set-url';
import ComposedPageLayout from '../shared/components/layout/composed-page-layout';
import { SignInButton } from '../auth/components/sign-in-button';

const Login: NextPage = () => {
  const { appAuthService } = useAuthContext();
  const router = useRouter();
  const breakpoint = parseInt(breakpoints['upto-md'].max, 10);
  const [imgSrcSet, setImgSrcSet] = useState<string>('');
  const [imgSrcSetWebp, setImgSrcSetWebp] = useState<string>('');

  useEffect(() => {
    if (router.query.redirect) {
      const redirectUri = URI(decodeURI(router.query.redirect as string));

      if (
        redirectUri.hasQuery('fromAcs', 'true') ||
        redirectUri.hasQuery('fromLogin', 'true')
      ) {
        redirectUri.removeQuery('fromAcs');
        redirectUri.removeQuery('fromLogin');
        appAuthService!.login(redirectUri.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.redirect]);

  useEffect(() => {
    if (router.query.iframe) {
      appAuthService!.login('/iframe-callback');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.iframe]);

  useEffect(() => {
    if (router.query.fromAcs && appAuthService) {
      appAuthService.login();
    }
  }, [router.query.fromAcs, appAuthService]);

  useEffect(() => {
    getValue(LOGIN_BANNER_KEY).then((imgUrl) => {
      const quality =
        window.innerWidth && window.innerWidth < breakpoint ? 40 : 60;

      if (imgUrl) {
        setImgSrcSet(
          generateSrcSetUrl({
            url: imgUrl,
            width: 1152,
            height: 675,
            quality,
          })
        );
        setImgSrcSetWebp(
          generateSrcSetUrl({
            url: imgUrl,
            width: 1152,
            height: 675,
            quality,
            format: 'webp',
          })
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ComposedPageLayout>
      <div data-testid="detail-hero" className="c-detail-hero u-h-full">
        <div className="c-detail-hero__inner">
          <h1 className="c-detail-hero__title">
            Sign in to your Digital Theatre+
          </h1>

          <SignInButton />
          {router.query.loginError && (
            <p className="u-mt-5 u-p-5 u-bg-white u-text-red-darker u-font-bold">
              {router.query.loginError}
            </p>
          )}

          <p className="u-mt-10">
            <span>Don&apos;t have a subscription? </span>
            <Link href="https://www.digitaltheatreplus.com/" passHref>
              <a className="type-inline-link type-inline-link--light">
                Find out more
              </a>
            </Link>
          </p>
        </div>

        <div className="c-detail-hero__image-container">
          {imgSrcSet && imgSrcSetWebp && (
            <picture>
              <source srcSet={imgSrcSetWebp} type="image/webp" />
              <img data-testid="login-hero-image" srcSet={imgSrcSet} alt="" />
            </picture>
          )}
        </div>
      </div>
    </ComposedPageLayout>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const authenticatedUser = await authenticateUser(context);

  if (authenticatedUser.isAuthenticated) {
    const redirect = context.query?.redirect || '/';

    return {
      redirect: {
        destination: redirect as string,
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
};

export default Login;
